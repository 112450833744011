import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loadingbox from "../components/LoadingBox";
import { signin } from "../actions/userActions";
import MessageBox from "../components/MessageBox";
import Header from "../components/Header";
import { USER_SIGNIN_FAIL, USER_SIGNIN_REQUEST, USER_SIGNIN_SUCCESS } from "../constants/userConstants";

function LoginScreen(props) {

  const [passCode, setPassCode] = useState('');
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, loading } = userSignin;

  const dispatch = useDispatch();
  
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch({
      type: USER_SIGNIN_REQUEST,
    });
    if (passCode === '0430496430') {
      dispatch({
            type: USER_SIGNIN_SUCCESS,
            payload: 'Login Successful',
      });
      props.history.push('/dashboard');
    } else {
      dispatch({
        type: USER_SIGNIN_FAIL,
            payload: "You have entered wrong pass code."
      });
    }
  };




  return (
    <>
      <Header />
        <div id="pages_maincontent">
          <h2 className="page_title" >
            <i className="fa fa-sign-in" aria-hidden="true" />
              &nbsp;Login Page
          </h2>
          <h3 id="dvResellerName" >
            Login here
          </h3>
        <div className="page_single layout_fullwidth_padding">
          <div className="loader">
            {loading && <Loadingbox/>}
                  {userInfo && userInfo.error && <MessageBox variant="danger">{userInfo.error}</MessageBox>}
          </div>
          <form onSubmit={(e)=>submitHandler(e)}>
            <div className="contactform" id="dvform">
              <div className="form_row">
                <label>Enter PassCode: </label>
              <input required type="password" value={passCode} onChange={(e) => setPassCode(e.target.value)}
                placeholder="Enter Your PassCode" className="form_input required" />
              </div>
              <input type="submit" className="form_submit" value="Login" />
            </div>
            </form>
          </div>
        </div>
      </>
    );
  }
export default LoginScreen;