import { ACCESS_COMPANIES_LIST_FAIL, ACCESS_COMPANIES_LIST_REQUEST, ACCESS_COMPANIES_LIST_SUCCESS, COMPANY_DETAILS_FAIL, COMPANY_DETAILS_REQUEST, COMPANY_DETAILS_SUCCESS, COMPANY_LIST_FAIL, COMPANY_LIST_REQUEST, COMPANY_LIST_SUCCESS, COMPANY_REGISTER_FAIL, COMPANY_REGISTER_REQUEST, COMPANY_REGISTER_SUCCESS } from "../constants/companyConstants";

export const companyRegisterReducer = (state = {}, action) => {
    switch (action.type) {
        case COMPANY_REGISTER_REQUEST:
            return { loading: true };
        case COMPANY_REGISTER_SUCCESS:
            return {
                loading: false,
                success: true
            };
        case COMPANY_REGISTER_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
};

export const companyListReducer = (state = {}, action) => {
    switch (action.type) {
        case COMPANY_LIST_REQUEST:
            return { loading: true };
        case COMPANY_LIST_SUCCESS:
            return {
                loading: false,
                companies: action.payload
            };
        case COMPANY_LIST_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
};

export const companyAccessReducer = (state = {}, action) => {
    switch (action.type) {
        case ACCESS_COMPANIES_LIST_REQUEST:
            return { loading: true };
        case ACCESS_COMPANIES_LIST_SUCCESS:
            return {
                loading: false,
                accessableCompanies: action.payload
            };
        case ACCESS_COMPANIES_LIST_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
};