import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Route
} from "react-router-dom";
import LoginScreen from '../screens/LoginScreen';
import DashboardScreen from '../screens/DashboardScreen';

import CompaniesList from '../screens/CompaniesList';
import NewCompany from '../screens/CompanyForm';
import CompaniesAccessScreen from '../screens/CompaniesAccessScreen';
import EditLoginScreen from '../screens/EditLoginScreen';

export default class Routers extends Component {
    render() {
        return (
            <Router>
                <Route path='/' exact component={LoginScreen} />
                <Route path='/company/:id' exact component={NewCompany} />
                <Route path='/companies' exact component={CompaniesList} />
                <Route path='/companies/access/:id' exact component={CompaniesAccessScreen} />
                <Route path='/companies/edit/login/:id' exact component={EditLoginScreen} />
                <Route path='/dashboard' exact component={DashboardScreen} />
            </Router>
       )
    }
};