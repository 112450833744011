import { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import { Link } from "react-router-dom";
import { companiesList, resetPincode } from "../actions/companyActions";
import Header from "../components/Header";
import Loadingbox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import Axios from 'axios';
import { serverAddress } from "../constants/dbConstants";

function CompaniesList(props) {
  const [selectedClient, setSelectedClient] = useState({});
  const [editFlag, setEditFlag] = useState(false);

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, pinCodeLogin } = userSignin;

  const companyList = useSelector((state) => state.companyList);
  const { companies, loading } = companyList;

  const [dropDown, setDropDown] = useState('');
  
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(companiesList());

  }, [dispatch]);

  // useEffect(() => {
  //   if (!pinCodeLogin) {
  //     props.history.push('/');
  //   }
  //   }, [props.history, userInfo, pinCodeLogin]);

  const resetHandler = async (id) => {
    if (window.confirm("Are you sure, you want to reset Pincode ?")) {
          try {
        const { data } = await Axios.get(`${serverAddress}/api/companies/pincode/${id}`);
            alert("Pincode reset Successful");
     } catch (err) {
            alert("Error reseting Pincode");
    }
    }
  }

  return (
      <>
      <Header />
              <div id="pages_maincontent">
                <h2 className="page_title" >
                  <i onClick={props.history.goBack} className="fa fa-chevron-left back-ico" aria-hidden="true"></i>
                  <i className="fas fa-users"></i>
                  &nbsp;Companies
                </h2>
                <h3 id="dvResellerName" >
          Companies list
                </h3>
        
        <div className="page_single layout_fullwidth_padding">
          {companyList && companies?
          <ul className="list">
              {companies.map((v, i) =>
                <li key={i}>
                  <div className="details">
                    <div><b>{v.users[0].userName} {v.companyName && ` ( ${v.companyName} ) `}</b></div>
                    <div>
                      <span className="first">
                        {v.phone}
                      </span>
                      <span>
                        {v.email}
                      </span>
                    </div>
                  </div>
                  <div className="actions">
                    <div class="dropdown">
                      {/* <button class="dropbtn">Right</button> */}
                    {/* <i onClick={() => { editCustomer(i); setEditFlag(true); }} className="fas fa-ellipsis-h dropbtn" aria-hidden="true" /> */}
                      {dropDown === i ?
                        <i onClick={() => setDropDown('')} className="fas fa-times" aria-hidden="true" />
                        : <i onClick={() => setDropDown(i)} className="fas fa-ellipsis-h dropbtn" aria-hidden="true" />}
                      <div class="dropdown-content" style={dropDown===i?{display: 'block'}:{display:'none'}}>
                        <Link to={`/company/${v._id}`}>Edit</Link>
                        <Link to={`/companies/edit/login/${v._id}`}>Login Details</Link>
                        <Link onClick={() => resetHandler(v._id)}>Reset Pin</Link>
                        {v.isAccountingCompany &&
                          <Link onClick={() => props.history.push(`/companies/access/${v._id}`)}>Manage Access</Link>}
                        {/* <Link>List</Link> */}
                      </div>
                    </div>
                  </div>
                </li>
              )}
                    
            </ul> : <Loadingbox />}
                </div>
      </div>
      </>
    );
  }
    export default CompaniesList;