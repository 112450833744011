import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { registerCompany, updateCompany, uploadLogo } from '../actions/companyActions';
import Header from '../components/Header'
import Loadingbox from '../components/LoadingBox'
import MessageBox from '../components/MessageBox'

function CompanyForm(props) {
    const id = props.match.params.id;
    console.log("id==>", id);

    const companyRegister = useSelector((state) => state.companyRegister);
    const { loading, error, success } = companyRegister;
    
    const companyList = useSelector((state) => state.companyList);
    const { companies } = companyList;

    let company = null;

    if (id !== 'new') {
        company = companies.filter(x => x._id === id)[0];
    }
    
    console.log("company==>", company);

    const [formStep, setFormStep] = useState(1);
    const [companyName, setCompanyName] = useState(id!=='new'?company.companyName:'');
    const [isAccountingCompany, setIsAccountingCompany] = useState(id!=='new'?company.isAccountingCompany:false);
    const [email, setEmail] = useState(id!=='new'?company.email:'');
    const [website, setWebsite] = useState(id!=='new'?company.website:'');
    const [phone, setPhone] = useState(id!=='new'?company.phone:'');
    const [ABN, setABN] = useState(id!=='new'?company.ABN:'');
    
    const [userName, setUserName] = useState(id!=='new'?company.users[0].userName:'');
    const [userEmail, setUserEmail] = useState(id!=='new'?company.users[0].email:'');
    const [userPassword, setUserPassword] = useState(id!=='new'?'':'');
    
    const [settingsPage, setSettingsPage] = useState(id!=='new'?company.settingsPage:false);
    const [active, setActive] = useState(id!=='new'?company.active:false);
    const [themeColor, setThemeColor] = useState(id!=='new'?company.settings.themeColor:'#07B4A6');
    const [logoType, setLogoType] = useState(id!=='new'?company.logoType:'url');
    const [logoName, setLogoName] = useState();
    const [logoUrl, setLogoUrl] = useState(id!=='new'?company.logoUrl:'');
    const [initialNumber, setInitialNumber] = useState(id!=='new'?company.settings.initialNumber:'');
    const [dropboxKey, setDropboxKey] = useState(id!=='new'?company.settings.dropboxKey:'');
    const [driveApi, setDriveApi] = useState(id!=='new'?company.settings.driveApi:'');
    const [stripePubKey, setStripePubKey] = useState(id!=='new'?company.settings.stripePubKey:'');
    const [stripeSecret, setStripeSecret] = useState(id!=='new'?company.settings.stripeSecret:'');
    const [dsClientID, setDsClientID] = useState(id!=='new'?company.settings.dsClientID:'');
    const [dsClientSecret, setDsClientSecret] = useState(id!=='new'?company.settings.dsClientSecret:'');

    const [successMessage, setSuccessMessage] = useState(false);

    const dispatch = useDispatch();


    useEffect(() => {
        if(success){
            setSuccessMessage(true);
            setTimeout(()=>{setSuccessMessage(false)},2000)
        }
      }, [success]);

      console.log("logo type==>", logoType);
    const submitHandler = (e) => {
        e.preventDefault();
        if (formStep === 1) {
            if(id==='new'){
                setFormStep(2);
            }else{
                setFormStep(3);
            }
        } else if (formStep === 2) {
            setFormStep(3);
        } else if (formStep === 3) {
            if (id!=='new') {
                dispatch(updateCompany({
                    _id:company._id,
                    companyName,
                    email,
                    website,
                    phone,
                    ABN,
                    settingsPage,
                    active,
                    themeColor,
                    logoType,
                    logoName,
                    logoUrl,
                    initialNumber,
                    dropboxKey,
                    driveApi,
                    stripePubKey,
                    stripeSecret,
                    dsClientID,
                    dsClientSecret
            }));                
            } else {
                dispatch(registerCompany({
                    companyName,
                    email,
                    website,
                    phone,
                    ABN,
                    userName,
                    userEmail,
                    userPassword,
                    settingsPage,
                    active,
                    themeColor,
                    logoUrl,
                    logoType,
                    logoName,
                    initialNumber,
                    dropboxKey,
                    driveApi,
                    stripePubKey,
                    stripeSecret,
                    dsClientID,
                    dsClientSecret
                }));
            }
        }
    };

    const uploadHandler = event => {
        console.log("function running")
        const files = event.target.files;
        const formData = new FormData()
        formData.append('myFile', files[0]);
        setLogoName(files[0].name);
        dispatch(uploadLogo(formData));
        // setTimeout(() => {
        //     console.log("dir==>", currentDirectory)
        //     // dispatch(getEntries())
        //     let tempDirectory = currentDirectory;
        //     let tempChildren = currentDirectory.children;
        //     tempChildren.push({
        //         children: [],
        //         name: files[0].name,
        //         path: `${currentPath}/${files[0].name}`,
        //         size: 0,
        //         type: "file"})
        //     tempDirectory.children = tempChildren;
        //     dispatch(selectDirectory(tempDirectory))
        //     // dispatch(getEntries())
        //     setFileUpload(false)
        // }, 1000);
    }


    return (
        <>
            <Header />         
            <div id="pages_maincontent">
                <h2 className="page_title" >
                    {formStep === 1 ?
                        <i onClick={props.history.goBack} className="fa fa-chevron-left back-ico" aria-hidden="true"></i>
 :
                        <i onClick={() => setFormStep(1)} className="fa fa-chevron-left back-ico"
                            aria-hidden="true"></i>}
                    {id!=='new'? 'Update' :<>
                    <i className="fa fa-plus" aria-hidden="true" />                 
                     &nbsp;Register</>}
                </h2>
                {formStep === 1 ?
                    <h3 id="dvResellerName" >
                        {id!=='new'?'Update Company Details':
                        'Enter Company Details'}
                </h3> :formStep === 2 ?
                    <h3 id="dvResellerName" >
                        {id!=='new'?'Update User Details':
                        'Enter User Details'}
                        </h3> :
                <h3 id="dvResellerName" >
                        {id!=='new'?'Update Settings':
                        'Enter Settings'}
                        </h3>
                }
                <div className="page_single layout_fullwidth_padding">                   
                    <div className="loader">                       
                        {loading && <Loadingbox />}                        
                        {error && <MessageBox variant="danger">{error}</MessageBox>}                        
                    </div>
                    <form onSubmit={(e)=>submitHandler(e)}>
                    <div className="contactform" id="dvform">
                        {formStep === 1 ?
                                <>
                                <div className="form_row">
                                    <label>Company Name: </label>
                                    <input  type="text" value={companyName} onChange={(e) => setCompanyName(e.target.value)}
                                        placeholder="Enter Company Name" className="form_input required" />
                                </div>
                                <div className="form_row">
                                    <label>Email: </label>
                                    <input  type="email" value={email} onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Enter Email" className="form_input required" />
                                </div>
                                <div className="form_row">
                                    <label>Webiste: </label>
                                    <input type="text" value={website} onChange={(e) => setWebsite(e.target.value)}
                                        placeholder="Enter Website" className="form_input required" />
                                </div>
                                <div className="form_row">
                                    <label>Phone: </label>
                                    <input  type="text" value={phone} onChange={(e) => setPhone(e.target.value)}
                                        placeholder="Enter Phone" className="form_input required" />
                                </div>
                                <div className="form_row">
                                    <label>ABN: </label>
                                    <input type="number" value={ABN} onChange={(e) => setABN(e.target.value)}
                                        placeholder="Enter ABN" className="form_input required" />
                                </div>
                            </> :formStep === 2 ?
                            <>
                            <div className="form_row">
                                <label>User Name: </label>
                                <input  type="text" value={userName} onChange={(e) => setUserName(e.target.value)}
                                    placeholder="Enter User Name" className="form_input required" />
                            </div>
                            <div className="form_row">
                                <label>User Email: </label>
                                <input  type="email" value={userEmail} onChange={(e) => setUserEmail(e.target.value)}
                                    placeholder="Enter User Email" className="form_input required" />
                            </div>
                            <div className="form_row">                           
                                <label>User Password: </label>                      
                                <input  type="password" value={userPassword} onChange={(e) => setUserPassword(e.target.value)}
                                placeholder="Enter User Password" className="form_input required" />
                        </div>
                            </>:formStep === 3 ?
                                        <>
                                        <label><b>Accounting Company:</b></label>
                                            <div className="gst-box">
                                                <span className="gst-span">
                                                    <input id="accountingtrue" type='radio' name="accounting-company"
                                                    onClick={() => setIsAccountingCompany(true)} checked={isAccountingCompany} />
                                                    <label htmlFor="accountingtrue">True</label>
                                                </span>
                                                <span className="gst-span">
                                                    <input id="accountingfalse" type='radio' name="accounting-company"
                                                        onClick={() => setIsAccountingCompany(false)} checked={!isAccountingCompany} />
                                                    <label htmlFor="accountingfalse">False</label>
                                                </span>
                                            </div>
                                        {!isAccountingCompany && <>
                                            <label><b>Settings Page:</b></label>
                                            <div className="gst-box">
                                                <span className="gst-span">
                                                    <input id="settingtrue" type='radio' name="settings"
                                                    onClick={() => setSettingsPage(true)} checked={settingsPage} />
                                                    <label htmlFor="settingtrue">Included</label>
                                                </span>
                                                <span className="gst-span">
                                                    <input id="settingfalse" type='radio' name="settings"
                                                        onClick={() => setSettingsPage(false)} checked={!settingsPage} />
                                                    <label htmlFor="settingfalse">Not Included</label>
                                                </span>
                                            </div></>}
                                            
                                <label><b>Company Active:</b></label>
                                            
                                <div className="gst-box">
                                <span className="gst-span">
                                    <input id="companyActive" type='radio' name="active"
                                    onClick={() => setActive(true)} checked={active} />
                                    <label htmlFor="companyActive">Yes</label>
                                </span>
                                <span className="gst-span">
                                    <input id="companyInactive" type='radio' name="active"
                                        onClick={() => setActive(false)} checked={!active} />
                                    <label htmlFor="companyInactive">No</label>
                                </span>
                                </div>
                                {!isAccountingCompany && 
                                <div className="form-group color">
                                    <label className='mr-3' htmlFor="color"><b>Invoice Theme Color:</b></label>
                                    <input type="color" id="color" value={themeColor} onChange={(e)=>setThemeColor(e.target.value)} list="reds" />
                                    
                                    <datalist id="reds">
                                    <option>#07B4A6</option>
                                    <option>#328FF6</option>
                                    <option>#5E5500</option>
                                    <option>#EA6858</option>
                                    <option>#47C6F0</option>
                                    <option>#5A8E8C</option> 
                                    <option>#A52A2A</option> 
                                    </datalist>
                                    {/* <input value={themeColor} onChange={(e) => setThemeColor(e.target.value)} type="color"
                                    id="color" aria-describedby="emailHelp" /> */}
                                </div>}
                                {!isAccountingCompany && <>
                                <label><b>Logo Image Type:</b></label>
                                <div className="gst-box">
                                    <span className="gst-span">
                                        <input id="logo-url" type='radio' name="logo-type"
                                        onClick={() => setLogoType('url')} checked={logoType=='url'} />
                                        <label htmlFor="logo-url">Image Url</label>
                                    </span>
                                    <span className="gst-span">
                                        <input id="logo-upload" type='radio' name="logo-type"
                                            onClick={() => setLogoType('upload')} checked={logoType==='upload'} />
                                        <label htmlFor="logo-upload">Image Upload</label>
                                    </span>
                                </div>
                                {logoType==='url' &&
                                <div className="form_row">
                                    <label>Logo Url: </label>
                                    <input type="text" value={logoUrl} onChange={(e) => setLogoUrl(e.target.value)}
                                        placeholder="Enter Image Url" className="form_input required" />
                                </div>}
                                {logoType==='upload' &&
                                <div className="form_row">
                                    <label>Logo Image: </label>
                                    <input type="file"  onChange={(e) => uploadHandler(e)}
                                         className="form_input required" />
                                </div>}
                                </>}
                                {!isAccountingCompany && 
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1"><b>Invoice Starting Number:</b></label>
                                    <input required value={initialNumber} onChange={(e)=>setInitialNumber(e.target.value)} min='10000' type="Number" placeholder="Enter initial number" className="form_input required" />
                                </div>}
                                {!isAccountingCompany && 
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1"><b>Dropbox App Key:</b></label>
                                    <input type="text" value={dropboxKey} onChange={(e) => setDropboxKey(e.target.value)}
                                        placeholder="DropBox App key" className="form_input required" /> 
                                </div>}
                                {!isAccountingCompany && 
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1"><b>Google Drive API:</b></label>
                                    <input type="text" value={driveApi} onChange={(e) => setDriveApi(e.target.value)}
                                        placeholder="Google Drive API" className="form_input required" />
                                </div>}
                                {!isAccountingCompany && 
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1"><b>Stripe Publishable Key:</b></label>
                                    <input type="text" value={stripePubKey} onChange={(e) => setStripePubKey(e.target.value)}
                                        placeholder="Stripe Puslishable Key" className="form_input required" />
                                </div>}
                                {!isAccountingCompany && 
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1"><b>DocuSign Client ID:</b></label>
                                    <input type="text" value={dsClientID} onChange={(e) => setDsClientID(e.target.value)}
                                        placeholder="Enter DocuSign Client ID" className="form_input required" />
                                </div>}
                                {!isAccountingCompany && 
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1"><b>DocuSign Client Secret:</b></label>
                                    <input type="text" value={dsClientSecret} onChange={(e) => setDsClientSecret(e.target.value)}
                                        placeholder="Enter DocuSign Client Secret" className="form_input required" />
                                </div>}
                                {!isAccountingCompany && 
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1"><b>Stripe Secret:</b></label>
                                    <input type="text" value={stripeSecret} onChange={(e) => setStripeSecret(e.target.value)}
                                        placeholder="Stripe Secret Key" className="form_input required" />
                                </div>}
                                {loading &&
                                <Loadingbox>Please wait...</Loadingbox>}
                                {successMessage && <div>
                                <MessageBox variant="success">Company updated successfully</MessageBox>
                                </div>}
                            </>
                            
                            :<></>}

                            {id ?
                                <input type="submit" className="form_submit" value={formStep === 3 ? 'Update' : 'Next'} /> :
                                <input type="submit" className="form_submit" value={formStep === 3 ? 'Register' : 'Next'} />}
                        </div>
                        </form>
                </div>
            </div>
        </>
    )
}

export default CompanyForm;
