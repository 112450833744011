import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCompanyLogin } from '../actions/companyActions';
import Header from '../components/Header';

function EditLoginScreen(props) {

    const id = props.match.params.id;
    console.log("id==>", id);
    
    const companyList = useSelector((state) => state.companyList);
    const { companies } = companyList;

    let company = null;

    if (id !== 'new') {
        company = companies.filter(x => x._id === id)[0];
    }
    
    console.log("company==>", company);
    const [userName, setUserName] = useState(id!=='new'?company.users[0].userName:'');
    const [userEmail, setUserEmail] = useState(id!=='new'?company.users[0].email:'');
    const [userPassword, setUserPassword] = useState(id!=='new'?'':'');

    const [showPassword, setShowPassword] = useState(false);

    const dispatch = useDispatch();

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(updateCompanyLogin(id, userName, userEmail, userPassword));
    }

    return (
        <>
            <Header />         
            <div id="pages_maincontent">
                <h2 className="page_title" >
                    <i className="fa fa-edit" aria-hidden="true" />                 
                     &nbsp;Edit
                </h2>
                    <h3 id="dvResellerName" >
                        Enter Login Details
                    </h3>
                
                <div className="page_single layout_fullwidth_padding">                   
                    {/* <div className="loader">                       
                        {loading && <Loadingbox />}                        
                        {error && <MessageBox variant="danger">{error}</MessageBox>}                        
                    </div> */}
                    <form onSubmit={(e)=>submitHandler(e)}>
                        <div className="contactform" id="dvform">
                            <div className="form_row">
                                <label>User Name: </label>
                                <input  type="text" value={userName} onChange={(e) => setUserName(e.target.value)}
                                    placeholder="Enter User Name" className="form_input required" />
                            </div>
                            <div className="form_row">
                                <label>User Email: </label>
                                <input  type="email" value={userEmail} onChange={(e) => setUserEmail(e.target.value)}
                                    placeholder="Enter User Email" className="form_input required" />
                            </div>
                            <div className="form_row">                           
                                <label>User Password: </label>
                                <div style={{position: 'relative'}}>                      
                                    <input type={showPassword?'text':'password'} value={userPassword} onChange={(e) => setUserPassword(e.target.value)}
                                        placeholder="Enter User Password" className="form_input required" />
                                    <i style={{position: 'absolute', right: '10px', top: '5px', fontSize: '30px', transition: '0.7s'}}
                                    onClick={()=>setShowPassword(!showPassword)} class={`fa ${showPassword?'fa-eye-slash':'fa-eye'}`} aria-hidden="true"></i>
                                </div>
                            </div>
                        <input type="submit" className="form_submit" value='Update' /> :
                        </div> 

                    </form>
                </div>
            </div>
        </>
    )
}

export default EditLoginScreen
