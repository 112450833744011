import { applyMiddleware, createStore, compose, combineReducers } from "redux";
import thunk from 'redux-thunk';
import { companyAccessReducer, companyListReducer, companyRegisterReducer } from "./reducers/companyReducers";
import { userSigninReducer } from "./reducers/userReducer";


const initialState = {

};
const reducer = combineReducers({
    userSignin: userSigninReducer,
    companyRegister: companyRegisterReducer,
    companyList: companyListReducer,
    accessableCompaniesList: companyAccessReducer

});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer,initialState, composeEnhancer(applyMiddleware(thunk)));

export default store;