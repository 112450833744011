import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Link} from 'react-router-dom'
import { getCompanyInfo } from '../actions/companyActions';
import Header from '../components/Header';
import DropboxChooser from 'react-dropbox-chooser';

function DashboardScreen(props) {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;    
  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfo !== 'Login Successful') {
      props.history.push('/')
    }
    
  }, [userInfo, props.history]);

  const fileSuccess = (files) => {
    console.log(files);
     window.open(files[0].link, '_blank');
  }
  return (
    <>   
    <Header />   
    <div id="pages_maincontent" >
      <div className="log">
        <h2 className="page_title" >
          <i className="fa fa-home" aria-hidden="true" />
          &nbsp;Dashboard 
        </h2>
      {/* <i onClick={()=>signoutHandler()} className="fa fa-sign-out" aria-hidden="true"></i> */}
      </div>
      <h3 id="dvResellerName" >
          Welcome Admin
        </h3>
        <br />
      <div className="page_single layout_fullwidth_padding">
        <Link to="/company/new" className="homepage_button btn btn-primary btn-lg btn-block">
            New Company</Link>
        <Link to="/companies" className="homepage_button btn btn-primary btn-lg btn-block">
            Companies List
        </Link>



          {/* <DropboxChooser 
            appKey={'pam5pajwn6jesas'}
            success={files => fileSuccess(files)}
            multiselect={false}
            extensions={['.pdf']} >
          <Link className=" dropbox-button homepage_button btn btn-primary btn-lg btn-block">
            Dropbox Folder
        </Link>
          </DropboxChooser> */}
      </div>
      </div>
    </>
    );
  }
export default DashboardScreen;