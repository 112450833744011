export const COMPANY_REGISTER_REQUEST = 'COMPANY_REGISTER_REQUEST';
export const COMPANY_REGISTER_SUCCESS = 'COMPANY_REGISTER_SUCCESS';
export const COMPANY_REGISTER_FAIL = 'COMPANY_REGISTER_FAIL';

export const COMPANY_LIST_REQUEST = 'COMPANY_LIST_REQUEST';
export const COMPANY_LIST_SUCCESS = 'COMPANY_LIST_SUCCESS';
export const COMPANY_LIST_FAIL = 'COMPANY_LIST_FAIL';

export const PINCODE_RESET_REQUEST = 'PINCODE_RESET_REQUEST';
export const PINCODE_RESET_SUCCESS = 'PINCODE_RESET_SUCCESS';
export const PINCODE_RESET_FAIL = 'PINCODE_RESET_FAIL';

export const ACCESS_COMPANIES_LIST_REQUEST = 'ACCESS_COMPANIES_LIST_REQUEST';
export const ACCESS_COMPANIES_LIST_SUCCESS = 'ACCESS_COMPANIES_LIST_SUCCESS';
export const ACCESS_COMPANIES_LIST_FAIL = 'ACCESS_COMPANIES_LIST_FAIL';