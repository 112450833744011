import Axios from "axios";
import { ACCESS_COMPANIES_LIST_FAIL, ACCESS_COMPANIES_LIST_REQUEST, ACCESS_COMPANIES_LIST_SUCCESS, COMPANY_LIST_FAIL, COMPANY_LIST_REQUEST, COMPANY_LIST_SUCCESS, COMPANY_REGISTER_FAIL, COMPANY_REGISTER_REQUEST, COMPANY_REGISTER_SUCCESS, PINCODE_RESET_FAIL, PINCODE_RESET_REQUEST, PINCODE_RESET_SUCCESS } from "../constants/companyConstants";
import { serverAddress } from "../constants/dbConstants";

export const registerCompany = ({
    companyName,
    email,
    website,
    phone,
    ABN,
    userName,
    userEmail,
    userPassword,
    settingsPage,
    active,
    themeColor,
    logoType,
    logoName,
    logoUrl,
    initialNumber,
    dropboxKey,
    driveApi,
    stripePubKey,
    stripeSecret,
    dsClientID,
    dsClientSecret
}) => async (dispatch) => {
    dispatch({
        type: COMPANY_REGISTER_REQUEST,
        payload: {
            companyName,
            email,
            website,
            phone,
            ABN,
            userName,
            userEmail,
            settingsPage,
            active,
            themeColor,
            logoUrl,
            logoType,
            logoName,
            initialNumber,
            dropboxKey,
            driveApi,
            stripePubKey,
            stripeSecret,
            dsClientID,
            dsClientSecret
        }
    });
    try {
        const { data } = await Axios.post(`${serverAddress}/api/companies/new`, {
            companyName,
            email,
            website,
            phone,
            ABN,
            userName,
            userEmail,
            userPassword,
            settingsPage,
            active,
            themeColor,
            logoUrl,
            logoType,
            logoName,
            initialNumber,
            dropboxKey,
            driveApi,
            stripePubKey,
            stripeSecret,
            dsClientID,
            dsClientSecret
        });
        dispatch({
            type: COMPANY_REGISTER_SUCCESS,
            payload: data,
        });
    } catch (err) {
        dispatch({
            type: COMPANY_REGISTER_FAIL,
            payload: err.response && err.response.data.message ?
                err.response.data.message : err.message,
        });
    }
}

export const updateCompany = ({
                _id,
                companyName,
                email,
                website,
                phone,
                ABN,
                settingsPage,
                active,
                themeColor,
                logoUrl,
                logoType,
                logoName,
                initialNumber,
                dropboxKey,
                driveApi,
                stripePubKey,
                stripeSecret,
                dsClientID,
                dsClientSecret
}) => async (dispatch) => {
    dispatch({
        type: COMPANY_REGISTER_REQUEST,
        payload: {
                companyName,
                email,
                website,
                phone,
                ABN,
                settingsPage,
                active,
                themeColor,
                logoUrl,
                logoType,
                logoName,
                initialNumber,
                dropboxKey,
                driveApi,
                stripePubKey,
                stripeSecret,
                dsClientID,
                dsClientSecret
        }
    });
    try {
        const { data } = await Axios.post(`${serverAddress}/api/companies/update/${_id}`, {
                companyName,
                email,
                website,
                phone,
                ABN,
                settingsPage,
                active,
                themeColor,
                logoUrl,
                logoType,
                logoName,
                initialNumber,
                dropboxKey,
                driveApi,
                stripePubKey,
                stripeSecret,
                dsClientID,
                dsClientSecret
        });
        dispatch({
            type: COMPANY_REGISTER_SUCCESS,
            payload: data,
        });
     } catch (err) {
        dispatch({
            type: COMPANY_REGISTER_FAIL,
            payload: err.response && err.response.data.message ?
                err.response.data.message : err.message,
        });
    }
    };


    export const updateCompanyLogin = (
        _id,
        userName,
        userEmail,
        userPassword,
    ) => async (dispatch) => {
        dispatch({
            type: COMPANY_REGISTER_REQUEST,
            payload: {
                userName,
                userEmail,
                userPassword
            }
        });
        try {
            const { data } = await Axios.post(`${serverAddress}/api/companies/update/login/${_id}`, {
                userName,
                userEmail,
                userPassword,
            });
            dispatch({
                type: COMPANY_REGISTER_SUCCESS,
                payload: data,
            });
        } catch (err) {
            dispatch({
                type: COMPANY_REGISTER_FAIL,
                payload: err.response && err.response.data.message ?
                    err.response.data.message : err.message,
            });
        }
    }
    

export const companiesList = () => async (dispatch) => {
    
    dispatch({
        type: COMPANY_LIST_REQUEST,
    });
    try {
        const { data } = await Axios.get(`${serverAddress}/api/companies/list`);
        console.log(data)
        // localStorage.setItem('companyDetails', JSON.stringify(data));
        dispatch({
            type: COMPANY_LIST_SUCCESS,
            payload: data,
        });
     } catch (err) {
        dispatch({
            type: COMPANY_LIST_FAIL,
            payload: err.response && err.response.data.message ?
                err.response.data.message : err.message,
        });
    }
};

export const resetPincode = (id) => async (dispatch) => {
    dispatch({
        type: PINCODE_RESET_REQUEST,
    });
    try {
        const { data } = await Axios.get(`${serverAddress}/api/companies/pincode/${id}`);
        console.log(data)
        // localStorage.setItem('companyDetails', JSON.stringify(data));
        dispatch({
            type: PINCODE_RESET_SUCCESS,
            payload: data,
        });
     } catch (err) {
        dispatch({
            type: PINCODE_RESET_FAIL,
            payload: err.response && err.response.data.message ?
                err.response.data.message : err.message,
        });
    }
};

export const getAccessableCompanies = (id) => async (dispatch) => {
    
    dispatch({
        type: ACCESS_COMPANIES_LIST_REQUEST,
    });
    try {
        const { data } = await Axios.get(`${serverAddress}/api/companies/access/${id}`);
        console.log(data)
        // localStorage.setItem('companyDetails', JSON.stringify(data));
        dispatch({
            type: ACCESS_COMPANIES_LIST_SUCCESS,
            payload: data,
        });
     } catch (err) {
        dispatch({
            type: ACCESS_COMPANIES_LIST_FAIL,
            payload: err.response && err.response.data.message ?
                err.response.data.message : err.message,
        });
    }
};

export const addNewAccess = (id, company) => async (dispatch) => {
    
    try {
        const { data } = await Axios.post(`${serverAddress}/api/companies/newaccess/${id}`, {
            company
        });
        console.log(data)
        // getAccessableCompanies();
        // localStorage.setItem('companyDetails', JSON.stringify(data));
        // dispatch({
        //     type: ACCESS_COMPANIES_LIST_SUCCESS,
        //     payload: data,
        // });
     } catch (err) {
        // dispatch({
        //     type: ACCESS_COMPANIES_LIST_FAIL,
        //     payload: err.response && err.response.data.message ?
        //         err.response.data.message : err.message,
        // });
    }
};

export const deleteCompanyAccess = (id, company) => async (dispatch) => {
    try {
        const { data } = await Axios.post(`${serverAddress}/api/companies/removeaccess/${id}`, {
            company
        });
        console.log(data)
        // getAccessableCompanies();
        // localStorage.setItem('companyDetails', JSON.stringify(data));
        // dispatch({
        //     type: ACCESS_COMPANIES_LIST_SUCCESS,
        //     payload: data,
        // });
     } catch (err) {
        // dispatch({
        //     type: ACCESS_COMPANIES_LIST_FAIL,
        //     payload: err.response && err.response.data.message ?
        //         err.response.data.message : err.message,
        // });
    }
};



export const uploadLogo = (file) => async (dispatch) => {
    // const user = localStorage.getItem('userInfo') ?
    //       JSON.parse(localStorage.getItem('userInfo'))
    //       : null;
    // const token = user.token;
    // console.log("token==>",token)
    //   dispatch({
    //       type: UPLOAD_FILE_REQUEST,
    //   });
    try {
      const { data } = await Axios.post(`${serverAddress}/api/companies/upload/logo`, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      // dispatch(getEntries(currentPath));
    //   dispatch({
    //     type: UPLOAD_FILE_SUCCESS,
    //     payload: data,
    //   });
      // currentPath(data.path);
      // localStorage.setItem('fileSystem', JSON.stringify(data));
        
    } catch (err) {
    //   dispatch({
    //     type: UPLOAD_FILE_FAIL,
    //     payload: err.response && err.response.data.message ?
    //       err.response.data.message : err.message,
    //   });
    }
  };