import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { addNewAccess, getAccessableCompanies, deleteCompanyAccess } from '../actions/companyActions';
import Header from '../components/Header'
import Loadingbox from '../components/LoadingBox'

function CompaniesAccessScreen(props) {
    let id = props.match.params.id;

    const companyList = useSelector((state) => state.companyList);
    const { companies, loading } = companyList;
    
    const accessableCompaniesList = useSelector((state) => state.accessableCompaniesList);
    const { accessableCompanies } = accessableCompaniesList;
    
  const [addCompany, setAddCompany] = useState('');
  const [dropDown, setDropDown] = useState('')
    // let filtererdCompanies =  companies?.filter(f => accessableCompanies?.map(x => x._id == f._id));
    // console.log("filteredCompamnies", filtererdCompanies)
    const dispatch = useDispatch();

  const allowCompany = () => {
    dispatch(addNewAccess(id, addCompany));
    setTimeout(() => {
      dispatch(getAccessableCompanies(id));
    }, 1000);
  };

  const deleteHandler = (company) => {
    dispatch(deleteCompanyAccess(id,company));
    setTimeout(() => {
      dispatch(getAccessableCompanies(id));
    }, 1000);
  };
    useEffect(() => {
        if (id) {
            dispatch(getAccessableCompanies(id));
        }
    }, []);

    return (
      <>
      <Header />
              <div id="pages_maincontent">
                <h2 className="page_title" >
                  <i onClick={props.history.goBack} className="fa fa-chevron-left back-ico" aria-hidden="true"></i>
                  <i className="fas fa-users"></i>
                  &nbsp;Companies
                </h2>
                <h3 id="dvResellerName" >Accessable Companies</h3>
        
                <div className="page_single layout_fullwidth_padding">
                    <div className="contactform">
                        
                <div className="form_row pt-2">
                  <h4 htmlFor="Select Client"> Add Company: </h4>
                  <select required onClick={(e)=>setAddCompany(e.target.value)}  
                  className="form-select form-select-sm" aria-label=".form-select-sm example">
                    <option value='' default>Please select company</option>
                    {companyList && companies.map((v,i)=>
                        <option key={i} value={v._id}>{v.companyName}</option>
                    )}
                    {/* <option value='Quotation'>Quotation</option>
                    <option value='Receipt'>Receipt</option> */}
                  </select>
                </div>    
                        {addCompany != '' && <button onClick={()=>allowCompany()}>Add</button>}
                </div>
          {accessableCompaniesList && accessableCompanies?
          <ul className="list">
              <h4>Accessable Companies</h4>
              {accessableCompanies.map((v, i) =>
                <li key={i}>
                  <div className="details">
                    <div><b>{v.companyName}</b></div>
                    <div>
                      <span className="first">
                        {v.phone}
                      </span>
                      <span>
                        {v.email}
                      </span>
                    </div>
                  </div>
                  <div className="actions">
                    <div class="dropdown">
                     {dropDown === i ?
                        <i onClick={() => setDropDown('')} className="fas fa-times" aria-hidden="true" />
                        : <i onClick={() => setDropDown(i)} className="fas fa-ellipsis-h dropbtn" aria-hidden="true" />}
                      <div class="dropdown-content" style={dropDown===i?{display: 'block'}:{display:'none'}}>
                          <Link onClick={() => deleteHandler(v._id)}>Delete</Link>
                      </div>
                    </div>
                  </div>
                </li>
              )}
                    
            </ul> : <Loadingbox />}
                </div>
      </div>
      </>
    )
}

export default CompaniesAccessScreen
